
import 'vue-glide-js/dist/vue-glide.css';
import { Glide, GlideSlide } from 'vue-glide-js';

export default {
    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        selectedProduct: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            active: 0,
            key: 1,
        };
    },
    computed: {
        isSelected() {
            return this.selectedProduct && this.selectedProduct.uid !== 'none';
        },
        mediaGallery() {
            if (this.isSelected && this.selectedProduct.media_gallery) {
                return this.prepareGallery([
                    ...this.selectedProduct.media_gallery.map(this.prepareSelectedImage),
                    ...this.product.media_gallery.map(this.prepareDefaultImage),
                ]);
            } else {
                return this.prepareGallery(this.product.media_gallery.map(this.prepareDefaultImage));
            }
        },
        mainImage() {
            return this.isSelected ? this.selectedProduct.image : this.product.image;
        },
    },
    mounted() {
        // Adjusting slide sizes in case when glide-slide calculations were made incorrectly. In some random cases it happens.
        const galleryContainer = document.getElementsByClassName('product-image-gallery-container');
        const galleryItems = document.getElementsByClassName('product-image-gallery-item');

        if (galleryContainer && galleryItems) {
            const slideWidth = galleryContainer[0].clientWidth;

            for (let i = 0; i < galleryItems.length; i++) {
                const expectedWidth = `${slideWidth}px`;
                if (galleryItems[i].style.width !== expectedWidth) {
                    galleryItems[i].style.width = expectedWidth;
                }
            }
        }
    },
    fetchKey() {
        return `image-gallery-${this.product.sku}`;
    },
    methods: {
        prepareSelectedImage(image) {
            return {
                ...image,
                label: image.label || this.selectedProduct.name,
                imageComment: this.selectedProduct.name,
            };
        },
        prepareDefaultImage(image) {
            return {
                ...image,
                label: image.label || this.product.name,
                imageComment: null,
            };
        },
        prepareGallery(gallery) {
            if (!gallery) {
                return [];
            }

            return gallery.filter((item) => !item.disabled).sort((itemA, itemB) => itemA.position - itemB.position);
        },
    },
};
