
export default {
    props: {
        attributes: {
            type: Array,
            required: true,
        },
    },
    computed: {
        visibleAttributes() {
            const disAllowedCodes = ['packaging_unit', 'packaging_amount', 'clearance_product', 'available_on_demand', 'is_available_on_demand'];

            return this.attributes.filter((attribute) => !disAllowedCodes.some((code) => attribute.attribute_metadata.code.startsWith(code)));
        },
        attributesLayout() {
            return this.visibleAttributes.map((attribute) => {
                const selectedValue = attribute.selected_attribute_options.attribute_option;
                const enteredValue = attribute.entered_attribute_value.value;
                const attributeValue = selectedValue === null ? enteredValue : selectedValue.map((value) => value.label).join(' ');
                return {
                    label: attribute.attribute_metadata.label,
                    value: attributeValue,
                };
            });
        },
    },
};
