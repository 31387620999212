
export default {
    props: {
        path: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            product: null,
            pimage: null,
            review: false,
            backPath: '/',
            groupedQty: null,
            selectedProduct: { uid: 'none' },
        };
    },
    async fetch() {
        const {
            data: {
                products: { items },
            },
        } = await this.$axios.$get(`/product/${this.path}`);

        const [product] = items;
        if (!product) {
            this.$nuxt.error({ statusCode: 404, message: 'Product not found' });
            throw new Error('Product not found');
        }
        this.product = product;
        this.pimage = product.small_image.url;
        try {
            const skus = this.$storage.getCookie('recently_viewed');
            if (!skus) {
                const sku = [this.product.sku];
                this.$storage.setCookie('recently_viewed', sku);
            } else if (!skus.includes(this.product.sku)) {
                skus.push(this.product.sku);
                this.$storage.setCookie('recently_viewed', skus);
            }
        } catch (e) {
            console.error(e);
        }
    },
    computed: {
        manufacturer() {
            if (this.product) {
                const value = this.product.custom_attributes.find((attribute) => attribute.attribute_metadata.code === 'manufacturer');

                return value?.selected_attribute_options?.attribute_option[0].label;
            }

            return '';
        },
        /**
         * Find the breadcrumbs that has the most categories
         * @returns {*|*[]}
         */
        category() {
            if (this.product) {
                let len = 0;
                let result = null;
                [...this.product.categories].forEach((item) => {
                    if (item.breadcrumbs?.length && len < item.breadcrumbs?.length) {
                        len = item.breadcrumbs?.length;
                        result = item;
                    }
                });

                return result ? result.breadcrumbs : [];
            }

            return [];
        },
        crossSellProducts() {
            return this.product.crosssell_products && this.product.crosssell_products.length;
        },
    },
    methods: {
        scrollToDescription() {
            this.$scrollTo('#description-section');
        },
        setSelectedProduct(item) {
            this.selectedProduct = item && item.product && item.media_gallery ? item.product : { uid: 'none' };
        },
    },
    fetchKey: 'product-page',
};
